<template>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" flat dense>
          <v-toolbar-title>GPM Roles</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-tabs background-color="accent" color="white">
                <v-tab to="/gpm/roles">Roles</v-tab>
                <v-tab to="/gpm/roles/create">Create Role</v-tab>
            </v-tabs>
          </v-row>
          <v-row>
            <router-view />
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </template>
  
  <script>
  export default {
    data() {
      return {};
    },
    methods: {
      getOrders() {
        // const self = this;
        this.$store
          .dispatch("get", `/getopenorders`)
          .then(() => {})
          .catch((err) => {
            this.$refs.snackbar.show(err, "red");
          });
      },
    },
    created() {
      //  this.getOrders();
    },
  };
  </script>
  
  <style lang="scss" scoped>
  </style>